.forms-list {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    padding: 25px;
    border-radius: 4px;

    &__page-title {
        font-size: 20px;
        font-weight: 600;
    }

    &__btn-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__sort-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        gap: 20px;
    }

    &__sort-wrapper {
        display: flex;
    }

    &__filter-dropdown {
        width: 250px;

        @media (max-width: 450px) {
            width: 150px;
        }
    }

    .MuiOutlinedInput-root {
        height: 40px;
    }

    .forms-list__filter-dropdown {
        .MuiOutlinedInput-input {
            padding: 0 !important;
        }
    }
}
