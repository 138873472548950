.kt-grid--animateContent-finished {
    transform: none !important;
}

.dnd {
    &__draggble {
        background-color: #fff;
        min-height: 120px;
        border-bottom: 1px solid lightgray;
        padding: 20px 25px 20px 25px;
    }

    &__draggble-btn-container {
        display: flex;
        width: 100%;
    }

    &__draggble-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        width: 20px;
        margin-bottom: 20px;

        @media (max-width: 450px) {
            width: 25px;
        }
    }

    &__draggble-spacer {
        width: 50%;
    }

    &__draggble-point {
        width: 3px;
        height: 3px;
        background-color: darkgray;
        border-radius: 50%;
    }

    &__draggble-body {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__draggble-section {
        width: 95%;
        min-height: 60px;
    }

    &__draggble-question {
        display: flex;
        gap: 20px;
        min-height: 60px;

        @media (max-width: 450px) {
            flex-direction: column;
        }
    }

    &__draggble-question-content {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    &__textfield {
        width: 100%;
    }

    &__question-textfield {
        width: 70%;

        @media (max-width: 450px) {
            width: 95%;
        }
    }

    &__question-textfield-number {
        margin-right: 15px !important;
        width: 100px;

        .MuiOutlinedInput-root {
            height: 30px !important;
        }
    }

    &__delete {
        margin-top: 7px;
        height: 25px;
        cursor: pointer;
        transition: 0.2s;
        opacity: 0.7;

        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }

    &__add_variant {
        color: #3669ff;
        font-weight: 600;
        margin-top: 20px;

        span {
            cursor: pointer;
        }
    }

    &__question-dropdown {
        width: 45%;
        height: 40px;
        min-width: 200px;

        @media (max-width: 450px) {
            width: 100%;
        }

        .custom-radio__title {
            @media (max-width: 450px) {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .custom-radio {
            @media (max-width: 450px) {
                width: 100%;
            }

            &__elem {
                @media (max-width: 450px) {
                    width: 100%;
                }
            }
        }
    }

    &__range-input {
        height: 60px;
        margin-right: 20px;

        .MuiFormControl-root {
            width: 130px;
        }
    }

    &__submit-container {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
    }
}
