.create-report-page {
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] { 
        padding-right: 35px;
    }

    .top-buttons-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .page-title {
        font-size: 25px;
        font-weight: 500;
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .body-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    .column {
        &:first-child {
            display: flex;
            flex-wrap: wrap;
            max-width: 926px;
        }

        &:last-child {
        }
    }

    .elem-container {
        // width: 240px;
        display: flex;
        justify-content: space-between;
        margin: 10px 10px 0px;


        .MuiOutlinedInput-input {
            padding: 5px 14px !important;
        }
    }

    .date-container {
        // width: 250px;
        display: flex;
        justify-content: space-between;
        margin: 10px 10px 0px;

        .elem-title {
            top: 10px;
        }
    }

    .elem-title {
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        position: relative;
        top: 10px;
        margin-right: 10px;
    }

    .main-wrapper {
        margin-top: 15px;
        margin-bottom: 45px;
    }
    .elem-container--margin{
        margin-left: 100px;
    }


}
@media screen and (max-width:500px ) {
    .elem-container--margin{
        margin-left: 10px!important;
    }

    .create-report-page {
        .page-title {
            font-size: 20px;
            font-weight: 600;
            margin: 5px;
        }

        .top-buttons-wrapper {
            display: block;
        }

        .button-wrapper {
            display: block;
        }

        .elem-container, 
        .date-container {
            margin-left: 0 !important;
        }

        .elem-title {
            font-size: 14px;
            width: 90px;
        }

        .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon,
        .ffiuIu .picker {
            width: 150px !important;
        }

    }
}