.settingtable {
    min-width: 100%;
    overflow: hidden;
    overflow-x: auto;

    table {
        width: 100%;
    }

    thead {
        tr {
            td {
                height: 50px;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }

    tbody {
        tr {
            transition: background-color 0.3s ease-out;

            &:hover {
                background-color: #dee2e6;
            }

            td {
                font-weight: 400;

                &.p25 {
                    padding-left: 25px !important;
                }
            }
        }
    }

    tr {
        border-bottom: 1px solid #dee2e6;

        td {
            text-align: center;
            padding: 8px 10px;
            font-size: 14px;

            &.min {
                padding: 0 10px;

                .MuiFormControl-marginNormal,
                .picker {
                    margin: 8px 0;
                }
            }

            &:first-child {
                text-align: start;
            }
        }
    }
}

.nowrap thead tr td {
    white-space: nowrap;
}

.cell-25 {
    width: 25%;
}

.pointer {
    cursor: pointer;
}
.settingtable tbody tr td.left-content,
.settingtable thead tr td.left-content {
    text-align: left;
}
