.reports-list {
    .button-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .button-wrapper-2 {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .search-field {
        width: 150px;
    }

    .pagination-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .desktop-vis {
            display: none;

            @media screen and (min-width: 760px) {
                display: flex;
            }
        }

        .mobile-width-100p {
            @media screen and (max-width: 760px) {
                width: 100%;
            }
        }

        .mobile-vis {
            display: flex;

            @media screen and (min-width: 760px) {
                display: none;
            }
        }

        .header-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .MuiTab-wrapper {
            font-size: 12px;
            font-weight: 700;
        }

        .btn {
            margin-right: 15px;
        }

        .edit-all {
            height: 100%;
            border-radius: 4px;
            background-color: #eef1ff;
            padding: 0 12px;
            font-weight: 600;
            font-size: 13px;
            line-height: 19px;
            color: #5d78ff;
            display: flex;
            align-items: center;
        }

        .pagination {
            margin: 0;

            @media (max-width: 600px) {
                margin: 10px auto;
            }

            &-wrap {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 10px;

                .MuiSelect-select {
                    padding: 7px;
                }
            }

            &-show {
                display: flex;
                align-items: center;
            }
        }

        .formControl {
            width: 150px;
            margin: 5px 10px;
        }
    }
}
