.intake-form-body {
    &__row-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    &__row-elem {
        width: 50%;

        @media (max-width: 450px) {
            .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
                padding-right: 20px !important;
            }
        }
    }

    &__input-container {
        width: 100%;
        height: 66px;
    }

    &__input-comntainer-multiselect {
        @media (max-width: 450px) {
            min-height: 66px;
            height: auto;
        }

        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            @media (max-width: 450px) {
                min-height: 40px;
                height: auto;
            }
        }
    }

    &__textfield {
        width: 100%;
    }

    .MuiOutlinedInput-root {
        height: 40px;
    }

    .MuiOutlinedInput-input {
        padding: 0;
    }

    .MuiAutocomplete-tag {
        margin-top: -5px;

        @media (max-width: 450px) {
            margin-top: 5px;
        }
    }
}
